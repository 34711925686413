<template>
  <div class="pageContainer" style="">
    <div
    class="row   position-relative m-0 p-0 w-100 big-div-quiz"
   
    
    :style="getPageDesign()"
  >
    <!-- BackGroundDiv start-->
  
    <div
       class="bg lazyload"
          :data-bg="MainImgSrc(pageDesign.imageBlock.src)"
      v-if="pageDesign.pageStyles.imageAlign == 'no'"
      :style="
getbackgroundImage(
IsImageAdded,
pageDesign.imageBlock,
pageDesign.pageStyles.imageAlign , isDesktopView
) + getBgDivStyles()
"
    ></div>
    <!-- BackGroundDiv end-->
   <div
      class=" px-0 px-0"
      v-show="showLeftImage && pageDesign.pageType !== 'resultPage'"
      :class="[{
        'col-12 col-lg-12 col-xs-12': !isDesktopView,
        'image-division col-6': isDesktopView,
      }]"
       :style="calculateImageWidth( pageDesign.imageBlock , isDesktopView , IsImageVariableWidthActive)"
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
   :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                    IsImageVariableWidthActive,
                    pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageRotation(pageDesign.imageBlock.style)
            "
            :key="MainImgSrc(ImgBlockImageSrc)"
            :class="[
              `pic-${order}`,
              ImgBlockImageSrc == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(ImgBlockImageSrc)"
            alt
/>
        </div>
      </div>
    </div>

    <div
      :class="[!IsImageAdded ? 'col-12' : imageAddedLayout]"
      class=" "
      :style="
        `zoom: ${IsImageAdded && (showLeftImage || showRightImage) ? '10%' : '15%'} ;`
        + calculateContentWidth( pageDesign.imageBlock , isDesktopView ,IsImageVariableWidthActive,pageDesign.pageStyles)
      "
    >
      <div class="">
            <div>
              <div v-if="pageDesign.pageType == 'resultPage'">
               
                  <div
                    v-for="(pro, ind) in pageDesign.blocksArray"
                    :key="'preview' + ind"
                  >
                    <div
                      id="preview"
                      :style="getHeightWidth(pro.style)"
                      class="content"
                      style="position: relative"
                    >
                     

                      <!-- <VueDragResize v-if="pro.type == 'text'"  :isActive="pro.customizeStatus" :w="pro.style.width" :h="pro.style.height" v-on:resizing="resize"  :isDraggable="false"> -->
                      <div
                        v-if="pro.type == 'text'"
                        :style="
                          getColorCss(pro.style) +
                          getPaddingCss(pro.style) +
                          getMarginCss(pro.style, isDesktopView) +
                          getColorWithOpacity(pro.style) +
                          getTextRotate(pro.style) +
                          getLineHeight(pro.style) +
                          getTextAlign(pro.style) +
                          getFontStyle(pro.style,isDesktopView)
                        "
                        v-html="pro.content"
                      ></div>
                      <div
                        v-if="pro.type == 'html'"
                        :style="
                          getMarginCss(pro.style, isDesktopView) +
                          getColorWithOpacity(pro.style) +
                          getTextRotate(pro.style) +
                          getTextAlign(pro.style)
                        "
                      >
                        <HtmlElement
                          :pro="pro"
                          :isDesktopView="isDesktopView"
                        />
                      </div>
                      <!-- </VueDragResize> -->
                      <!-- Products -->
                      <div v-if="pro.type == 'product'" style="maxheight: 100%">
                        <!-- Detail Only View  start-->
                        <div
                          class="row"
                          v-if="getViewType(pro.viewType) == 'detailOnly'"
                        >
                          <div v-if="pro.showListView" class="w-100">
                            <div
                              class="col-12"
                              v-for="(product, index) in getProducts(pro)"
                              :key="index"
                            >
                              <div
                                class="row m-0 p-0 mb-3"
                                :style="
                                  detailProductContainerStyle(isDesktopView) +
                                  getCardBackground(pro.style) +
                                  getCardBorderCSS(pro.style)
                                "
                              >
                                <div
                                  class="p-0 m-0"
                                  :class="
                                    getProductCarouselImageClass(isDesktopView)
                                  "
                                  :style="
                                    getProductCarouselImageStyle(isDesktopView)
                                  "
                                >
                                  <div class="w-100 h-100 position-relative">
                                    <img
                                      :src="product.image"
                                      alt=""
                                      :style="
                                        getDetailProductImageFit(pro.style) +
                                        getDetailProductImageRatio(pro.style) +
                                        getImageborderRadius(pro.style)
                                      "
                                    >

                                    <!--Product Rank Start -->
                                    <div
                                      v-if="
                                        pro.showProductRank &&
                                        index <=
                                          pro.productRankOption.length - 1
                                      "
                                      style="
                                        position: absolute;
                                        top: -2%;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        z-index: 100;
                                      "
                                      class="d-flex justify-content-center"
                                    >
                                      <p
                                        style="text-align: center"
                                        :style="getProductRankCss(pro.style)"
                                      >
                                        <span>{{
                                          pro.productRankOption[index].text
                                        }}</span>
                                      </p>
                                    </div>
                                    <!--Product Rank end -->
                                  </div>
                                </div>
                                <div
                                  class="h-100 w-100 d-flex flex-column"
                                  :style="getPagePadding(pro.style)"
                                  :class="
                                    isDesktopView ? ' col-md-6' : 'col-12'
                                  "
                                >
                                  <div class="d-flex flex-column flex-grow-1">
                                    <!-- Title & price -->
                                    <div>
                                      <p :style="getTitleStyles(pro.style)">
                                        {{ getProductInfoDetail(pro.useProductVariant, product,'title') }}
                                      </p>
                                    </div>
                                    <!-- Product Price , features  -->
                                    <div class="">
                                      <p
                                        class=""
                                        v-if="!isHidePrice"
                                        :style="getPriceColor(pro.style)"
                                      >
                                        <span
                                          v-if="currencyPos == 'left'"
                                          :class="currencySpace ? 'mr-1' : null"
                                        >
                                          {{ currentCurrency }}</span
                                        >{{ getProductInfoDetail(pro.useProductVariant, product,'price')
                                        }}<span
                                          v-if="currencyPos == 'right'"
                                          :class="currencySpace ? 'ml-1' : null"
                                          >{{ currentCurrency }}</span
                                        >
                                      </p>

                                      <div
                                        :style="
                                          getDescriptionStyles(isDesktopView)
                                        "
                                      >
                                        <div v-if="!pro.hideDescription">
                                          <!-- normal Description Start -->
                                          <div
                                            :style="
                                              getDescriptionStyles(
                                                isDesktopView
                                              )
                                            "
                                            v-if="!pro.useBulletDescription"
                                          >
                                            <p
                                              class="mb-0"
                                              :style="getPriceColor(pro.style)"
                                            >
                                              Lorem ipsum dolor sit amet,
                                              consectetur adipiscing elit, sed
                                              do eiusmod tempor incididunt ut
                                              labore et dolore magna aliqua. Ut
                                              enim ad minim veniam, quis nostrud
                                              exercitation ullamco laboris nisi
                                              ut aliquip ex ea commodo
                                              consequat. ng elit, sed do eiusmod
                                              tempor incididunt ut labore et
                                              dolore magna aliqua. Ut enim ad
                                              minim veniam, quis nostrud
                                              exercitation ullamco laboris nisi
                                              ut aliquip ex ea commodo
                                              consequat. Lorem ipsum dolor sit
                                              amet, consectetur adipiscing elit,
                                              sed do eiusmod tempor incididunt
                                              ut labore et dolore magna aliqua.
                                              Ut enim ad minim veniam, quis
                                              nostrud exercitation ullamco
                                              laboris nisi ut aliquip ex ea
                                              commodo consequat. ng elit, sed do
                                              eiusmod tempor incididunt ut
                                              labore et dolore magna aliqua. Ut
                                              enim ad minim veniam, quis nostrud
                                              exercitation ullamco laboris nisi
                                              ut aliquip ex ea commodo consequat
                                            </p>
                                          </div>
                                          <!-- normal Description End -->

                                          <!-- Bullet Description Start -->
                                          <div v-else>
                                            <div
                                              v-for="(
                                                features, index2
                                              ) in product.productAdvantage"
                                              :key="index2"
                                            >
                                              <div
                                                class="d-flex align-items-center mt-1"
                                                :style="
                                                  getBulletStyles(pro.style)
                                                "
                                              >
                                                <div class="mr-3">
                                                  <i
                                                    class="fa"
                                                    :class="
                                                      pro.selectedBulletIcon
                                                    "
                                                  ></i>
                                                </div>
                                                <span>{{ features.text }}</span>
                                              </div>
                                            </div>
                                          </div>

                                          <!-- Bullet Description End -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="mt-3">
                                     <div
                                        class="pb-2"
                                        v-if="pro.useProductVariant && product && product.variants"
                                      >
                                        <VaraintSelectComp :pro="pro" :product="product" />
                                      </div>
                                    <button
                                      v-if="pro.useAddToCart"
                                      :style="getProductButtonCss(pro.style)"
                                    >
                                      {{
                                        pro.useAddToCartText || "Add to Cart"
                                      }}
                                    </button>
                                    <button
                                      v-else
                                      :style="getProductButtonCss(pro.style)"
                                    >
                                      {{ pro.buttonText }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12" v-else>
                            <carousel
                              class="ProductCarousel"
                              :perPage="1"
                              :paginationEnabled="
                                checkProductLength(getProducts(pro))
                              "
                              :navigationEnabled="
                                checkProductLength(getProducts(pro))
                              "
                            >
                              <slide
                                v-for="(product, index) in getProducts(pro)"
                                :key="index"
                              >
                                <div
                                  class="row m-0 p-0"
                                  :style="
                                    detailProductContainerStyle(isDesktopView) +
                                    getCardBackground(pro.style) +
                                    getCardBorderCSS(pro.style)
                                  "
                                >
                                  <div
                                    class="p-0 m-0"
                                    :class="
                                      getProductCarouselImageClass(
                                        isDesktopView
                                      )
                                    "
                                    :style="
                                      getProductCarouselImageStyle(
                                        isDesktopView
                                      )
                                    "
                                  >
                                    <div
                                      class="w-100 h-100"
                                      style="position: relative"
                                    >
                                      <img
                                        :src="product.image"
                                        alt=""
                                        :style="
                                          getDetailProductImageFit(pro.style) +
                                          getDetailProductImageRatio(
                                            pro.style
                                          ) +
                                          getImageborderRadius(pro.style)
                                        "
                                      >
                                      <!--Product Rank Start -->
                                      <div
                                        v-if="
                                          pro.showProductRank &&
                                          index <=
                                            pro.productRankOption.length - 1
                                        "
                                        style="
                                          position: absolute;
                                          top: 0%;
                                          left:50%;
                                          transform:translateX(-50%)
                                          z-index: 100;
                                        "
                                        class="d-flex justify-content-center"
                                      >
                                        <p
                                          style="text-align: center"
                                          :style="getProductRankCss(pro.style)"
                                        >
                                          <!-- <span v-if="index == 0">1st</span>
                          <span v-if="index == 1">2nd</span>
                          <span v-if="index == 2">3rd</span> -->
                                          <span>{{
                                            pro.productRankOption[index].text
                                          }}</span>
                                        </p>
                                      </div>
                                      <!--Product Rank end -->
                                    </div>
                                  </div>
                                  <div
                                    class="h-100 w-100 d-flex flex-column"
                                    :style="getPagePadding(pro.style)"
                                    :class="
                                      isDesktopView ? ' col-md-6' : 'col-12'
                                    "
                                  >
                                    <div class="d-flex flex-column flex-grow-1">
                                      <!-- Title & price -->
                                      <div class="mb-2">
                                        <p
                                          class="mb-0"
                                          :style="getTitleStyles(pro.style)"
                                        >
                                          {{ getProductInfoDetail(pro.useProductVariant, product,'title') }}
                                        </p>
                                        <!-- Rating Div start -->
                                        <div
                                          v-if="
                                            product.productReview &&
                                            isJudgeMeInstalled
                                          "
                                          class="Review"
                                          v-html="product.productReview"
                                          :style="getPriceColor(pro.style)"
                                        ></div>
                                        <!-- Rating Div end -->
                                      </div>
                                      <!-- Product Price , features  -->
                                      <div class="">
                                        <p
                                          class=""
                                          v-if="!isHidePrice"
                                          :style="getPriceColor(pro.style)"
                                        >
                                          <span
                                            v-if="currencyPos == 'left'"
                                            :class="
                                              currencySpace ? 'mr-1' : null
                                            "
                                            >{{ currentCurrency }}</span
                                          >{{ getProductInfoDetail(pro.useProductVariant, product,'price')
                                          }}<span
                                            v-if="currencyPos == 'right'"
                                            :class="
                                              currencySpace ? 'ml-1' : null
                                            "
                                            >{{ currentCurrency }}</span
                                          >
                                        </p>

                                        <div
                                          :style="
                                            getDescriptionStyles(isDesktopView)
                                          "
                                        >
                                          <div v-if="!pro.hideDescription">
                                            <!-- normal Description Start -->
                                            <div
                                              :style="
                                                getDescriptionStyles(
                                                  isDesktopView
                                                )
                                              "
                                              v-if="!pro.useBulletDescription"
                                            >
                                              <p
                                                class="mb-0"
                                                :style="
                                                  getPriceColor(pro.style)
                                                "
                                              >
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                                Ut enim ad minim veniam, quis
                                                nostrud exercitation ullamco
                                                laboris nisi ut aliquip ex ea
                                                commodo consequat. ng elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                                Ut enim ad minim veniam, quis
                                                nostrud exercitation ullamco
                                                laboris nisi ut aliquip ex ea
                                                commodo consequat. Lorem ipsum
                                                dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et
                                                dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud
                                                exercitation ullamco laboris
                                                nisi ut aliquip ex ea commodo
                                                consequat. ng elit, sed do
                                                eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                                Ut enim ad minim veniam, quis
                                                nostrud exercitation ullamco
                                                laboris nisi ut aliquip ex ea
                                                commodo consequat
                                              </p>
                                            </div>
                                            <!-- normal Description End -->

                                            <!-- Bullet Description Start -->
                                            <div v-else>
                                              <div
                                                v-for="(
                                                  features, index2
                                                ) in product.productAdvantage"
                                                :key="index2"
                                              >
                                                <div
                                                  class="d-flex align-items-center mt-1"
                                                  :style="
                                                    getBulletStyles(pro.style)
                                                  "
                                                >
                                                  <div class="mr-3">
                                                    <i
                                                      class="fa"
                                                      :class="
                                                        pro.selectedBulletIcon
                                                      "
                                                    ></i>
                                                  </div>
                                                  <span>{{
                                                    features.text
                                                  }}</span>
                                                </div>
                                              </div>
                                            </div>

                                            <!-- Bullet Description End -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="mt-3">
                                       <div
                                        class="pb-2"
                                        v-if="pro.useProductVariant && product && product.variants"
                                      >
                                        <VaraintSelectComp :pro="pro" :product="product" />
                                      </div>
                                      <button
                                        v-if="pro.useAddToCart"
                                        :style="getProductButtonCss(pro.style)"
                                      >
                                        {{
                                          pro.useAddToCartText || "Add to Cart"
                                        }}
                                      </button>
                                      <button
                                        v-else
                                        :style="getProductButtonCss(pro.style)"
                                      >
                                        {{ pro.buttonText }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </slide>
                            </carousel>
                          </div>
                        </div>
                        <!-- Detail Only View  end-->

                        <!-- Detail- List View start-->

                        <div
                          class="row"
                          v-if="getViewType(pro.viewType) == 'detailList'"
                        >
                          <div class="col-12 mb-3">
                            <div
                              class="row m-0 p-0"
                              :style="
                                detailProductContainerStyle(isDesktopView) +
                                getCardBackground(pro.style) +
                                getCardBorderCSS(pro.style)
                              "
                            >
                              <div
                                class="p-0 m-0"
                                :class="
                                  getProductCarouselImageClass(isDesktopView)
                                "
                                :style="
                                  getProductCarouselImageStyle(isDesktopView)
                                "
                              >
                                <div class="w-100 h-100 position-relative">
                                  <img
                                    :src="SampleProducts[0].image"
                                    alt=""
                                    :style="
                                      getDetailProductImageFit(pro.style) +
                                      getDetailProductImageRatio(pro.style) +
                                      getImageborderRadius(pro.style)
                                    "
                                  >
                                  <!--Product Rank Start -->
                                  <div
                                    v-if="pro.showProductRank"
                                    style="
                                      position: absolute;
                                      top: -2%;
                                      left: 50%;
                                      transform: translateX(-50%);
                                      z-index: 100;
                                    "
                                    class="d-flex justify-content-center"
                                  >
                                    <p
                                      style="text-align: center"
                                      :style="getProductRankCss(pro.style)"
                                    >
                                      <!-- <span >1st</span> -->
                                      <span>{{
                                        pro.productRankOption[0].text
                                      }}</span>
                                    </p>
                                  </div>
                                  <!--Product Rank end -->
                                </div>
                              </div>
                              <div
                                class="h-100 w-100 d-flex flex-column"
                                :style="getPagePadding(pro.style)"
                                :class="
                                  getProductCarouselImageClass(isDesktopView)
                                "
                              >
                                <div class="d-flex flex-column flex-grow-1">
                                  <!-- Title & price -->
                                  <div class="mb-2">
                                    <p
                                      class="mb-0"
                                      :style="getTitleStyles(pro.style)"
                                    >
                                      {{ getProductInfoDetail(pro.useProductVariant, SampleProducts[0],'title') }}
                                    </p>

                                    <!-- Rating Div start -->
                                    <div
                                      v-if="
                                        SampleProducts[0].productReview &&
                                        isJudgeMeInstalled
                                      "
                                      class="Review"
                                      v-html="SampleProducts[0].productReview"
                                      :style="getPriceColor(pro.style)"
                                    ></div>
                                    <!-- Rating Div end -->
                                  </div>
                                  <!-- Product Price , features  -->
                                  <div class="">
                                    <p
                                      class=""
                                      v-if="!isHidePrice"
                                      :style="getPriceColor(pro.style)"
                                    >
                                      <span
                                        v-if="currencyPos == 'left'"
                                        :class="currencySpace ? 'mr-1' : null"
                                        >{{ currentCurrency }}</span
                                      >{{ getProductInfoDetail(pro.useProductVariant, SampleProducts[0],'price')
                                      }}<span
                                        v-if="currencyPos == 'right'"
                                        :class="currencySpace ? 'ml-1' : null"
                                        >{{ currentCurrency }}</span
                                      >
                                    </p>

                                    <div
                                      :style="
                                        getDescriptionStyles(isDesktopView)
                                      "
                                    >
                                      <div v-if="!pro.hideDescription">
                                        <!-- normal Description Start -->
                                        <div
                                          :style="
                                            getDescriptionStyles(isDesktopView)
                                          "
                                          v-if="!pro.useBulletDescription"
                                        >
                                          <p
                                            class="mb-0"
                                            :style="getPriceColor(pro.style)"
                                          >
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore
                                            et dolore magna aliqua. Ut enim ad
                                            minim veniam, quis nostrud
                                            exercitation ullamco laboris nisi ut
                                            aliquip ex ea commodo consequat. ng
                                            elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna
                                            aliqua. Ut enim ad minim veniam,
                                            quis nostrud exercitation ullamco
                                            laboris nisi ut aliquip ex ea
                                            commodo consequat. Lorem ipsum dolor
                                            sit amet, consectetur adipiscing
                                            elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna
                                            aliqua. Ut enim ad minim veniam,
                                            quis nostrud exercitation ullamco
                                            laboris nisi ut aliquip ex ea
                                            commodo consequat. ng elit, sed do
                                            eiusmod tempor incididunt ut labore
                                            et dolore magna aliqua. Ut enim ad
                                            minim veniam, quis nostrud
                                            exercitation ullamco laboris nisi ut
                                            aliquip ex ea commodo consequat
                                          </p>
                                        </div>
                                        <!-- normal Description End -->

                                        <!-- Bullet Description Start -->
                                        <div v-else>
                                          <div
                                            v-for="(
                                              features, index2
                                            ) in SampleProducts[0]
                                              .productAdvantage"
                                            :key="index2"
                                          >
                                            <div
                                              class="d-flex align-items-center mt-1"
                                              :style="
                                                getBulletStyles(pro.style)
                                              "
                                            >
                                              <div class="mr-3">
                                                <i
                                                  class="fa"
                                                  :class="
                                                    pro.selectedBulletIcon
                                                  "
                                                ></i>
                                              </div>
                                              <span>{{ features.text }}</span>
                                            </div>
                                          </div>
                                        </div>

                                        <!-- Bullet Description End -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="mt-3">
                                   <div
                                        class="pb-2"
                                        v-if="pro.useProductVariant && SampleProducts[0] && SampleProducts[0].variants"
                                      >
                                        <VaraintSelectComp :pro="pro" :product="SampleProducts[0]" />
                                      </div>
                                  <button
                                    v-if="pro.useAddToCart"
                                    :style="getProductButtonCss(pro.style)"
                                  >
                                    {{ pro.useAddToCartText || "Add to Cart" }}
                                  </button>
                                  <button
                                    v-else
                                    :style="getProductButtonCss(pro.style)"
                                  >
                                    {{ pro.buttonText }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 mb-3">
                            <p :style="getMoreOptionStyles(pro.style)">
                              {{ getMoreOptionText(pro.moreOptionText) }}
                            </p>
                          </div>
                          <div
                            :class="
                              isDesktopView
                                ? `col-${12 / pro.productsPerPage}`
                                : `col-${12 / pro.productsPerPageMobile}`
                            "
                            class="mb-3"
                            v-for="(product, index) in getDetailListProducts(
                              SampleProducts
                            )"
                            :key="index"
                            v-show="index < displayProductsLength(pro)"
                          >
                            <div
                              class="card shadow-sm d-flex flex-column h-100 mx-auto position-relative"
                              :style="
                                getCardBackground(pro.style) +
                                getCardBorderCSS(pro.style) +
                                getPagePadding(pro.style) +
                                getFontFamily(pro.style) +
                                ListProductMaxWidth(
                                  isDesktopView
                                    ? pro.productsPerPage
                                    : pro.productsPerPageMobile
                                )
                              "
                            >
                              <div
                                v-if="
                                  pro.showProductRank &&
                                  index <= pro.productRankOption.length - 1
                                "
                                style="
                                  position: absolute;
                                  top: -2%;
                                  left: 50%;
                                  transform: translateX(-50%);
                                  z-index: 100;
                                "
                                class="d-flex justify-content-center"
                              >
                                <p
                                  style="text-align: center"
                                  :style="getProductRankCss(pro.style)"
                                  v-if="pro.productRankOption[index + 1]"
                                >
                                  <!-- <span v-if="index == 0">2nd</span>
                          <span v-if="index == 1">3rd</span> -->
                                  <span>{{
                                    pro.productRankOption[index + 1].text
                                  }}</span>
                                </p>
                              </div>

                              <!-- <div
                                style="
                                  width: 100%;
                                  height: 150px;
                                  position: relative;
                                "
                              > -->
                              <div :style="ListImageAspectRatio(pro.style)">
                                <img
                                  :src="product.image"
                                  alt=""
                                  style="
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    right: 0;
                                    height: 100%;
                                    width: 100%;
                                  "
                                  :style="
                                    getImageFit(pro.style) +
                                    getImageTopBorderCSs(pro.style)
                                  "
                                >
                                <!-- getImageRatio(pro.style) + -->
                              </div>
                              <div
                                class="mt-2 flex-grow-1"
                                :style="getPaddingCss(pro.style)"
                              >
                                <div
                                  class="d-flex flex-column justify-content-between h-100"
                                >
                                  <!-- Product Title -->
                                  <div class="mb-2">
                                    <p
                                      class="mb-0"
                                      :style="getTitleStyles(pro.style)"
                                    >
                                      {{ getProductInfoDetail(pro.useProductVariant, product,'title') }}
                                    </p>
                                    <!-- Rating Div start -->
                                    <div
                                      v-if="
                                        product.productReview &&
                                        isJudgeMeInstalled
                                      "
                                      class="Review"
                                      v-html="product.productReview"
                                      :style="getPriceColor(pro.style)"
                                    ></div>
                                    <!-- Rating Div end -->
                                  </div>
                                  <!-- Product Price , features & buy button -->
                                  <div class="">
                                    <p
                                      v-if="!isHidePrice"
                                      :style="getPriceColor(pro.style)"
                                    >
                                      <span
                                        v-if="currencyPos == 'left'"
                                        :class="currencySpace ? 'mr-1' : null"
                                        >{{ currentCurrency }}</span
                                      >{{ getProductInfoDetail(pro.useProductVariant, product,'price')
                                      }}<span
                                        v-if="currencyPos == 'right'"
                                        :class="currencySpace ? 'ml-1' : null"
                                        >{{ currentCurrency }}</span
                                      >
                                    </p>

                                    <div
                                      v-if="
                                        !pro.hideDescription &&
                                        !pro.showHeirarchyDesciption
                                      "
                                    >
                                      <!-- normal Description Start -->
                                      <div
                                        :style="
                                          getDescriptionStyles(isDesktopView)
                                        "
                                        v-if="!pro.useBulletDescription"
                                      >
                                        <p
                                          class="mb-0"
                                          :style="getPriceColor(pro.style)"
                                        >
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore magna aliqua. Ut enim ad minim
                                          veniam, quis nostrud exercitation
                                          ullamco laboris nisi ut aliquip ex ea
                                          commodo consequat. ng elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore magna.
                                        </p>
                                      </div>
                                      <!-- normal Description End -->

                                      <!-- Bullet Description Start -->
                                      <div v-else>
                                        <div
                                          v-for="(
                                            features, index2
                                          ) in product.productAdvantage"
                                          :key="index2"
                                        >
                                          <div
                                            class="d-flex align-items-center mt-1"
                                            :style="getBulletStyles(pro.style)"
                                          >
                                            <div class="mr-3">
                                              <i
                                                class="fa"
                                                :class="pro.selectedBulletIcon"
                                              ></i>
                                            </div>
                                            <span>{{ features.text }}</span>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- Bullet Description End -->
                                    </div>

                                    <div class="mt-3">
                                       <div
                                        class="pb-2"
                                        v-if="pro.useProductVariant && product && product.variants"
                                      >
                                        <VaraintSelectComp :pro="pro" :product="product" />
                                      </div>
                                      <button
                                        v-if="pro.useAddToCart"
                                        :style="getProductButtonCss(pro.style)"
                                      >
                                        {{
                                          pro.useAddToCartText || "Add to Cart"
                                        }}
                                      </button>
                                      <button
                                        v-else
                                        :style="getProductButtonCss(pro.style)"
                                      >
                                        {{ pro.buttonText }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Detail- List View end-->
                        <!-- List View start-->
                        <div
                          class="row"
                          v-if="getViewType(pro.viewType) == 'list'"
                        >
                          <div
                            :class="
                              isDesktopView
                                ? `col-${12 / pro.productsPerPage}`
                                : `col-${12 / pro.productsPerPageMobile}`
                            "
                            class="mb-3"
                            v-for="(product, index) in SampleProducts"
                            :key="index"
                            v-show="index < displayProductsLength(pro)"
                          >
                            <!-- style="max-width:211px;" -->
                            <div
                              class="card shadow-sm d-flex flex-column h-100 mx-auto position-relative"
                              :style="
                                getCardBackground(pro.style) +
                                getCardBorderCSS(pro.style) +
                                getPagePadding(pro.style) +
                                getFontFamily(pro.style) +
                                ListProductMaxWidth(
                                  isDesktopView
                                    ? pro.productsPerPage
                                    : pro.productsPerPageMobile
                                )
                              "
                            >
                              <div
                                v-if="
                                  pro.showProductRank &&
                                  index <= pro.productRankOption.length - 1
                                "
                                style="
                                  position: absolute;
                                  top: -2%;
                                  left: 50%;
                                  transform: translateX(-50%);
                                  z-index: 100;
                                "
                                class="d-flex justify-content-center"
                              >
                                <p
                                  style="text-align: center"
                                  :style="getProductRankCss(pro.style)"
                                >
                                  <!-- <span v-if="index == 0">1st</span>
                          <span v-if="index == 1">2nd</span>
                          <span v-if="index == 2">3rd</span> -->
                                  <span>{{
                                    pro.productRankOption[index].text
                                  }}</span>
                                </p>
                              </div>

                              <!-- style="width:100%; height:150px; position:relative" -->
                              <div :style="ListImageAspectRatio(pro.style)">
                                <img
                                  :src="product.image"
                                  alt=""
                                  style="
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    right: 0;
                                    height: 100%;
                                    width: 100%;
                                  "
                                  :style="
                                    getImageFit(pro.style) +
                                    getImageTopBorderCSs(pro.style)
                                  "
                                >
                                <!-- +getImageRatio(pro.style) -->
                              </div>
                              <div
                                class="mt-2 flex-grow-1"
                                :style="getPaddingCss(pro.style)"
                              >
                                <div
                                  class="d-flex flex-column justify-content-between h-100"
                                >
                                  <!-- Product Title -->
                                  <div class="mb-2">
                                    <p
                                      class="mb-0"
                                      :style="getTitleStyles(pro.style)"
                                    >
                                      {{  getProductInfoDetail(pro.useProductVariant, product,'title')   }}
                                      <!-- product.title -->
                                    </p>
                                    <!-- Rating Div start -->
                                    <div
                                      v-if="
                                        product.productReview &&
                                        isJudgeMeInstalled
                                      "
                                      class="Review"
                                      v-html="product.productReview"
                                      :style="getPriceColor(pro.style)"
                                    ></div>
                                    <!-- Rating Div end -->
                                  </div>
                                  <!-- Product Price , features & buy button -->
                                  <div class="">
                                    <p
                                      v-if="!isHidePrice"
                                      :style="getPriceColor(pro.style)"
                                    >
                                      <span
                                        v-if="currencyPos == 'left'"
                                        :class="currencySpace ? 'mr-1' : null"
                                        >{{ currentCurrency }}</span
                                      >{{  getProductInfoDetail(pro.useProductVariant, product,'price')
                                      }}<span
                                        v-if="currencyPos == 'right'"
                                        :class="currencySpace ? 'ml-1' : null"
                                        >{{ currentCurrency }}</span
                                      >
                                    </p>

                                    <div v-if="!pro.hideDescription">
                                      <!-- normal Description Start -->
                                      <div
                                        :style="
                                          getDescriptionStyles(isDesktopView)
                                        "
                                        v-if="!pro.useBulletDescription"
                                      >
                                        <p
                                          class="mb-0"
                                          :style="getPriceColor(pro.style)"
                                        >
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore magna aliqua. Ut enim ad minim
                                          veniam, quis nostrud exercitation
                                          ullamco laboris nisi ut aliquip ex ea
                                          commodo consequat. ng elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore magna.
                                        </p>
                                      </div>
                                      <!-- normal Description End -->

                                      <!-- Bullet Description Start -->
                                      <div v-else>
                                        <div
                                          v-for="(
                                            features, index2
                                          ) in product.productAdvantage"
                                          :key="index2"
                                        >
                                          <div
                                            class="d-flex align-items-center mt-1"
                                            :style="getBulletStyles(pro.style)"
                                          >
                                            <div class="mr-3">
                                              <i
                                                class="fa"
                                                :class="pro.selectedBulletIcon"
                                              ></i>
                                            </div>
                                            <span>{{ features.text }}</span>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- Bullet Description End -->
                                    </div>

                                    <div class="mt-3">
                                      <div
                                        class="pb-2"
                                        v-if="pro.useProductVariant && product && product.variants"
                                      >
                                        <VaraintSelectComp :pro="pro" :product="product" />
                                      </div>

                                      <button
                                        v-if="pro.useAddToCart"
                                        :style="getProductButtonCss(pro.style)"
                                      >
                                        {{
                                          pro.useAddToCartText || "Add to Cart"
                                        }}
                                      </button>
                                      <button
                                        v-else
                                        :style="getProductButtonCss(pro.style)"
                                      >
                                        {{ pro.buttonText }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- List View end-->
                      </div>
                      <div
                        v-if="pro.type == 'button'"
                        :style="
                          'display:flex;justify-content:' +
                          pro.style.position +
                          ';'
                        "
                      >
                        <button
                          v-if="pro.buttonType == 'restart'"
                          :style="getButtonCss(pro.style, isDesktopView)"
                        >
                          <span
                            :style="{
                              opacity: `${pro.style.textOpacity}%`,
                              fontSize: `${pro.style.fontSize}px`,
                            }"
                          >
                            {{ pro.text }}
                          </span>
                          {{ pro.style.alignItems }}
                        </button>
                        <button
                          v-if="pro.buttonType == 'goToUrl'"
                          :style="getButtonCss(pro.style, isDesktopView)"
                        >
                          <span
                            :style="{
                              opacity: `${pro.style.textOpacity}%`,
                              fontSize: `${pro.style.fontSize}px`,
                            }"
                          >
                            {{ pro.text }}
                          </span>
                          {{ pro.style.alignItems }}
                        </button>
                        
                        <button
                          v-if="pro.buttonType == 'goToCurrentTab'||pro.buttonType == 'mailToOwner'||pro.buttonType == 'mailToCustomer'"
                          :style="getButtonCss(pro.style, isDesktop)"
                        >
                          <span
                            :style="{
                              opacity: `${pro.style.textOpacity}%`,
                              fontSize: `${pro.style.fontSize}px`,
                            }"
                          >
                            {{ pro.text }}
                          </span>
                          {{ pro.style.alignItems }}
                        </button>
                      </div>

                      <SocialLinksComp
                        v-if="pro.type == 'socialLinks'"
                        :pro="pro"
                        :isDesktopView="isDesktopView"
                      />
                    </div>
                  </div>
                
              </div>
              <div v-else>
                
                  <div
                    v-for="(pro, ind) in pageDesign.NoResultBlocksArray"
                    :key="'preview' + ind"
                    class=""
                  >
                    <div
                      id="preview"
                      :style="getHeightWidth(pro.style) "
                      class="content"
                      style="position: relative"
                    >
                      
                      <!-- <VueDragResize v-if="pro.type == 'text' "  :isActive="pro.customizeStatus" :w="pro.style.width" :h="pro.style.height" v-on:resizing="resize"  :isDraggable="false"> -->
                      <div
                        v-if="pro.type == 'text'"
                        :style="
                          getColorCss(pro.style) +
                          getPaddingCss(pro.style) +
                          getMarginCss(pro.style, isDesktopView) +
                          getColorWithOpacity(pro.style) +
                          getTextRotate(pro.style) +
                          getLineHeight(pro.style) +
                          getTextAlign(pro.style) +
                          getFontStyle(pro.style,isDesktopView)
                        "
                        v-html="pro.content"
                      ></div>

                      <!-- </VueDragResize> -->
                      <div
                        v-if="pro.type == 'button'"
                        :style="
                          'display:flex;justify-content:' +
                          pro.style.position +
                          ';'
                        "
                      >
                        <button
                          v-if="pro.buttonType == 'restart'"
                          :style="getButtonCss(pro.style, isDesktopView)"
                        >
                          <span
                            :style="{
                              opacity: `${pro.style.textOpacity}%`,
                              fontSize: `${pro.style.fontSize}px`,
                            }"
                          >
                            {{ pro.text }}
                          </span>
                        </button>
                        <button
                          v-if="pro.buttonType == 'goToUrl'"
                          :style="getButtonCss(pro.style, isDesktopView)"
                        >
                          <span
                            :style="{
                              opacity: `${pro.style.textOpacity}%`,
                              fontSize: `${pro.style.fontSize}px`,
                            }"
                          >
                            {{ pro.text }}
                          </span>
                        </button>
                        <button
                          v-if="pro.buttonType == 'goToCurrentTab'"
                          :style="getButtonCss(pro.style, isDesktopView)"
                        >
                          <span
                            :style="{
                              opacity: `${pro.style.textOpacity}%`,
                              fontSize: `${pro.style.fontSize}px`,
                            }"
                          >
                            {{ pro.text }}
                          </span>
                        </button>
                      </div>

                      <SocialLinksComp
                        v-if="pro.type == 'socialLinks'"
                        :pro="pro"
                        :isDesktopView="isDesktopView"
                      />
                    </div>
                  </div>
                
              </div>
            </div>
          </div>
    </div>

   <div
      class=" px-0 px-0"
      v-show="showRightImage && pageDesign.pageType !== 'resultPage'"
     :class="[{
'col-12 col-lg-12 col-xs-12': !isDesktopView,
'image-division col-6': isDesktopView,
}]"
 :style="calculateImageWidth( pageDesign.imageBlock , isDesktopView , IsImageVariableWidthActive)"
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
             <LazyImage
                  :style="
                  getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                    IsImageVariableWidthActive,
                    pageDesign.imageBlock
              ) +
                  getImageOpacity(pageDesign.imageBlock.style) +
                  getImageRotation(pageDesign.imageBlock.style)
                "
                :key="MainImgSrc(ImgBlockImageSrc)"
                :class="[
                  `pic-${order}`,
                  ImgBlockImageSrc == '/images/default_start_page.png'
                    ? 'default-bg'
                    : '',
                ]"
                :src="MainImgSrc(ImgBlockImageSrc)"
                alt
                  />
        </div>
      </div>
    </div>

    
  </div>
  </div>
</template>

<script>
import cssFunctionMixin from '../customize/mixin/cssFunction'
import { Carousel, Slide } from "vue-carousel";
import {mapGetters} from 'vuex'
export default {
  components:{
    Carousel,
    Slide,
    HtmlElement: () =>
      import(
        /* webpackChunkName: "HtmlElement" */ "../quizPreviewComp/HtmlElement.vue"
      ),
    SocialLinksComp: () =>
      import(
        /* webpackChunkName: "SocialLinksComp" */ "../quizPreviewComp/SocialLinksComp.vue"
      ),
      VaraintSelectComp: () =>
      import(
        /* webpackChunkName: "VaraintSelectComp" */ "../quizPreviewComp/VaraintSelectComp.vue"
      ),
  },
props:['pageDesign','order','CurrentPage'],
mixins: [cssFunctionMixin],
data(){
  return{
    currencyPos:'left',
    isDesktopView:true,
      SampleProducts: [
        {
          productId: 1,
          image:
            "https://cdn.shopify.com/s/files/1/0428/3872/0663/files/Default_image.png?v=1646417708",
          title: "Product  #1 with a big title.",
          price: "250",
          productReview:
            "<div style='display:none' class='jdgm-prev-badge' data-average-rating='5.00' data-number-of-reviews='2' data-number-of-questions='0'> <span class='jdgm-prev-badge__stars' data-score='5.00' tabindex='0' aria-label='5.00 stars' role='button'> <span class='jdgm-star jdgm--on'></span><span class='jdgm-star jdgm--on'></span><span class='jdgm-star jdgm--on'></span><span class='jdgm-star jdgm--on'></span><span class='jdgm-star jdgm--on'></span> </span> <span class='jdgm-prev-badge__text'> 2 reviews </span> </div>",
          productAdvantage: [
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
          ],
          selectedVariant:'',
          variants:[
            {
              compare_at_price:330,
              id:1,
              image:null,
price:150,
product_id:75091,
shopify_id:421895,
sku:"",
title:"Variant 1"
            },
            {
              compare_at_price:400,
              id:2,
              image:null,
price:350,
product_id:75092,
shopify_id:421895401,
sku:"",
title:"Variant 2"
            },
          ]
        },
        {
          productId: 2,
          image:
            "https://cdn.shopify.com/s/files/1/0428/3872/0663/files/Default_image.png?v=1646417708",
          title: "Product  #2 ",
          price: "250",
          productReview:
            '<div style="display:none" class="jdgm-prev-badge" data-average-rating="3.67" data-number-of-reviews="3" data-number-of-questions="0"> <span class="jdgm-prev-badge__stars" data-score="3.67" tabindex="0" aria-label="3.67 stars" role="button"> <span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--half"></span><span class="jdgm-star jdgm--off"></span> </span> <span class="jdgm-prev-badge__text"> 3 reviews </span> </div>',
          productAdvantage: [
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
          ],
        },
        {
          productId: 3,
          image:
            "https://cdn.shopify.com/s/files/1/0428/3872/0663/files/Default_image.png?v=1646417708",
          title: "Product  #3 ",
          price: "250",
          productReview:
            '<div style="display:none" class="jdgm-prev-badge" data-average-rating="3.67" data-number-of-reviews="3" data-number-of-questions="0"> <span class="jdgm-prev-badge__stars" data-score="3.67" tabindex="0" aria-label="3.67 stars" role="button"> <span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--half"></span><span class="jdgm-star jdgm--off"></span> </span> <span class="jdgm-prev-badge__text"> 3 reviews </span> </div>',
          productAdvantage: [
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
          ],
        },
        {
          productId: 4,
          image:
            "https://cdn.shopify.com/s/files/1/0428/3872/0663/files/Default_image.png?v=1646417708",
          title: "Product  #4 ",
          price: "250",
          productReview:
            '<div style="display:none" class="jdgm-prev-badge" data-average-rating="3.67" data-number-of-reviews="3" data-number-of-questions="0"> <span class="jdgm-prev-badge__stars" data-score="3.67" tabindex="0" aria-label="3.67 stars" role="button"> <span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--on"></span><span class="jdgm-star jdgm--half"></span><span class="jdgm-star jdgm--off"></span> </span> <span class="jdgm-prev-badge__text"> 3 reviews </span> </div>',
          productAdvantage: [
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
            {
              text: "Some Advantage",
            },
          ],
        },
      ],
      isJudgeMeInstalled:false
  }
},
computed:{
 ...mapGetters([
      
      "getEditorSettings",
    ]),
     currentCurrency() {
      return this.getEditorSettings.selectedCurrency.sign;
    },
    currencySpace() {
      if (this.getEditorSettings.selectedCurrency.sign == "RON") return true;
      return false;
    },
    isHidePrice() {
      return this.getEditorSettings.hidePrice == 1 ? true : false;
    },
showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.pageDesign.pageStyles.imageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.pageDesign.pageStyles.imageAlign == "left" ||
            this.pageDesign.pageStyles.imageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.pageDesign.pageStyles.imageAlign == "right"
      );
    },
     IsImageAdded() {
      if (this.pageDesign.imageBlock.src !== "") return true;
      return false;
    },
    IsImageVariableWidthActive(){
return this.IsImageAdded && this.pageDesign.pageStyles.imageAlign != "no" ? true : false
},
    imageAddedLayout() {
      if (this.pageDesign.pageStyles.imageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
     return "col-12 col-xs-12 col-sm-6 col-md-6  ";
    },
     ImgBlockImageSrc() {
      const selectImage = (defaultSrc, mobileSrc) => {
        if (this.isDesktopView) {
            return defaultSrc;
        }
        return this.pageDesign.imageBlock.useMobileImage ? mobileSrc : defaultSrc;
    };

    if (this.CurrentPageType === "resultPage" && this.pageDesign.imageBlock.src !== "") {
        return selectImage(this.pageDesign.imageBlock.src, this.pageDesign.imageBlock.useMobileImageSrc);
    }

    if (this.CurrentPageType === "noResultPage" && this.isNoResultSrcPresent && this.pageDesign.imageBlock.noResultSrc !== "") {
        return selectImage(this.pageDesign.imageBlock.noResultSrc, this.pageDesign.imageBlock.useMobileImageSrc);
    }

    return "";
    },
},
methods:{
      getBgDivStyles() {
      return `position:absolute;top: 0;
        bottom: 0;
        left: 0;
        right: 0;width: 100%;
          height: 100%; z-index: 0;`;
    },
  getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; width:" + this.width + "px;height:"+ this.height +"px;overflow:hidden;"

      return design;
    },
     ImageSrc(img) {
      // return img.startsWith('/http') ? img.substring(1) : img
      let newImage;
      if (img.startsWith("/http")) {
        newImage = img.substring(1);
      } else {
        newImage = img;
      }
      if (
        newImage.indexOf("http://") === 0 ||
        newImage.indexOf("https://") === 0
      ) {
        return newImage;
      } else {
        const baseURL = process.env.VUE_APP_API_BASE_URL;
        return baseURL + newImage.substring(1);
      }
    },
     getProductInfoDetail(isProductVarient,product,value){

     
        if (
          isProductVarient
        
      ) { 


        return  (product.selectedVariant && product.selectedVariant != '') ? product.selectedVariant[value]    : product[value]
        // return product.selectedVariant.title;
      } else {
        return product[value];
      }
    },
    getProducts(block) {
      this.key = this.keyValue + 1;
      if (block.limitProduct) {
        let Products = [...this.SampleProducts];
        if (
          block.limitProductLength &&
          block.limitProductLength < Products.length
        ) {
          let newArr = [];
          for (let i = 0; i < block.limitProductLength; i++) {
            newArr.push(Products[i]);
          }
          return newArr;
        } else {
          return Products;
        }
      } else {
        return this.SampleProducts;
      }
    },
    getViewType(value) {
      if (value) {
        return value;
      } else {
        return "list";
      }
    },
    getMoreOptionText(value) {
      if (value) {
        return value;
      } else {
        return "More options";
      }
    },
    checkProductLength(arr) {
      return arr.length > 1 ? true : false;
    },
     getbackgroundImage(IsImageAdded, property, imageAlign) {
      let backgroundPos;
      if (property.style.xCord && property.style.xCord) {
        backgroundPos = `background-position:${property.style.xCord}% ${property.style.yCord}%;`;
      } else {
        backgroundPos = `background-position:50% 50%;`;
      }

      if (
        this.pageDesign.pageType == "resultPage" &&
        IsImageAdded &&
        property.src !== ""
      ) {
        let filter;
        if (property.style.imageOpacity >= 0) {
          filter = `filter: brightness(${
            property.style.imageOpacity / 100
          }) contrast(${2 - property.style.imageOpacity / 100}) ;`;
        } else {
          filter = `filter: brightness(${
            property.style.imageOpacity / 100
          })  ;`;
        }

        return `background-image: url(${property.src}); background-repeat: no-repeat; background-size:  cover; ${backgroundPos} ${filter}`;
      } else if (
        this.pageDesign.pageType == "noResultPage" &&
        IsImageAdded &&
        property.noResultSrc !== "" &&
        imageAlign == "no"
      ) {
        let filter;
        if (property.style.imageOpacity >= 0) {
          filter = `filter: brightness(${
            property.style.imageOpacity / 100
          }) contrast(${2 - property.style.imageOpacity / 100}) ;`;
        } else {
          filter = `filter: brightness(${
            property.style.imageOpacity / 100
          })  ;`;
        }

        return `background-image: url(${property.noResultSrc}); background-repeat: no-repeat; background-size:  cover; ${backgroundPos} ${filter}`;
      }
    },
     getTitleStyles(property) {
      return (
        `color:${property?.titleColor || "#000"};opacity:${
          property?.titleTextOpacity || 100
        }%;` +
        " font-weight:" +
        property.fontWeight +
        "; font-style:" +
        property.fontStyle +
        ";text-decoration:" +
        property.textDecoration +
        ";font-size:" +
        property.fontSize +
        "px;font-family:" +
        property.fontFamily +
        "; text-align:" +
        property.textAlign +
        ";"
      );
    },
    getFontFamily(property) {
      return "font-family:" + property.fontFamily + ";";
    },
    getCardBackground(property) {
      return "background-color:" + property.backgroundColor + ";";
    },
    getPriceColor(property) {
      return (
        "color:" +
        property.priceColor +
        "; text-align:" +
        property.textAlign +
        ";"
      );
    },
    getCardBorderCSS(property) {
      return (
        " border-radius:" +
        property.borderRadius +
        "px; border:" +
        property.borderSize +
        "px solid " +
        property.borderColor +
        ";"
      );
    },
    getPagePadding(property) {
      return (
        "; text-align:" +
        property.textAlign +
        "; padding:" +
        property.pagePaddingTop +
        "px " +
        property.pagePaddingRight +
        "px " +
        property.pagePaddingBottom +
        "px " +
        property.pagePaddingLeft +
        "px; "
      );
    },
    getImageTopBorderCSs(property) {
      return (
        " border-top-left-radius:" +
        property.borderRadius +
        "px; border-top-right-radius:" +
        property.borderRadius +
        "px ; "
      );
    },
    getImageRatio(property) {
      let imageRatioCSS;
      if (property.imageRatio === "square") {
        imageRatioCSS = "";
      } else if (property.imageRatio === "potrait") {
        //  imageRatioCSS = `position:absolute;height:100%; width:66%; margin:auto; left:0; right:0;`
        imageRatioCSS = `position:absolute;height:auto; width:66%; margin:auto; left:0; right:0;`;
      } else {
        imageRatioCSS = `position:absolute; width:100%; height:66%; margin:auto; top:0; bottom:0;`;
      }

      return " width: 100% " + ";" + "height: 150px" + ";" + imageRatioCSS;
    },
    getImageFit(property) {
      let imageFitCSS;
      if (property.imageFit === "Fit") {
        imageFitCSS = "object-fit: contain" + ";";
      } else if (property.imageFit === "Fill") {
        imageFitCSS = "object-fit: fill" + ";";
      }
      return imageFitCSS;
    },
     displayProductsLength(element) {
      let totalProducts;
      if (this.isDesktopView) {
        totalProducts = element.productsPerPage * element.rows;
      } else {
        totalProducts =  element.mobileRows ?  element.productsPerPageMobile * element.mobileRows :  element.productsPerPageMobile * element.rows ;
      }
      return totalProducts;
    },
    getDetailListProducts(arr) {
      if (arr.length > 1) {
        const newArr = [...arr];
        newArr.shift();
        return newArr;
      }
    },
}
}
</script>

<style scoped>
.pageContainer{
  /* width: 100%;
  height: 100%; */
  max-height :100%;
  max-width :100%;
  z-index:-1000;
  pointer-events: none;
  /* padding:5px; */

}
</style>